.done-req {
  color: #22886e;
  background-color: white;
  border: none;
  border-top: 1px solid #c4c4c4;
  text-align: center;
  padding: 5px;
}
.active-req {
  color: #55b42d;
  background-color: white;
  border: none;
  border-top: 1px solid #c4c4c4;
  text-align: center;
  padding: 5px;
}
.cancelled-req {
  background-color: white;
  border: none;
  border-top: 1px solid #c4c4c4;
  text-align: center;
  padding: 5px;
}

.active-req > .fc-event-main {
  color: #55b42d;
  font-size: 14px;
}

.done-req > .fc-event-main {
  color: #22886e;
  font-size: 14px;
}

.cancelled-req > .fc-event-main {
  color: #c4314b;
  font-size: 14px;
}

.active-req > .fc-event-main:hover {
  color: #55b42d;
  font-size: 14px;
  opacity: 0.5;
}

.done-req > .fc-event-main:hover {
  color: #22886e;
  font-size: 14px;
  opacity: 0.5;
}

.cancelled-req > .fc-event-main:hover {
  color: #c4314b;
  font-size: 14px;
  opacity: 0.5;
}
.fc-toolbar-title {
  /* 11.09.2020, Wednesday */

  /* H6 headline */
  font-family: Work Sans;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 129.03% !important;
  /* identical to box height, or 21px */
  display: flex !important;
  align-items: center !important;
  letter-spacing: -0.01em !important;

  /* Black */
  color: #080505;
}

.fc-button-primary {
  color: white !important;
  background-color: #00ab55 !important;
  border: none !important;
  margin: 5px !important;
  text-transform: capitalize !important;
}

.fc-button-primary:focus {
  box-shadow: none !important;
}

.fc-col-header-cell {
  /* Rectangle */

  height: 38px;
  /* Black */
  background: #00ab55;
  color: #ffffff;
  /* Grey Light */
  border: 1px solid #c4c4c4;
  border-radius: 0px;
  padding-top: 5px !important;
}

.fc-col-header-cell a {
  color: #ffffff;
  text-decoration: none;
}

.fc-daygrid-day-top a {
  color: black;
  text-decoration: none;
}

.fc-day-disabled {
  background-color: white !important;
}
